import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as artist_45applyNiQUkFA4qAMeta } from "/app/pages/artist-apply.vue?macro=true";
import { default as _91name_93WIiw0SzjdyMeta } from "/app/pages/artists/[name].vue?macro=true";
import { default as indexj5aSWpAwb2Meta } from "/app/pages/artists/index.vue?macro=true";
import { default as aboutGlAwnqAYTEMeta } from "/app/pages/chefs-doeuvre/[id]/about.vue?macro=true";
import { default as _91inscriptionId_93tDR9QrAAX6Meta } from "/app/pages/chefs-doeuvre/[id]/index/[inscriptionId].vue?macro=true";
import { default as indexoR8tiwGpXoMeta } from "/app/pages/chefs-doeuvre/[id]/index.vue?macro=true";
import { default as _91id_936iBnDYM7gSMeta } from "/app/pages/chefs-doeuvre/[id].vue?macro=true";
import { default as ariadnaSBOaZhqS8lMeta } from "/app/pages/chefs-doeuvre/ariadna.vue?macro=true";
import { default as aboutxYr78EWhorMeta } from "/app/pages/collaborations/[id]/about.vue?macro=true";
import { default as _91inscriptionId_93dOqOent4PVMeta } from "/app/pages/collaborations/[id]/index/[inscriptionId].vue?macro=true";
import { default as indexfL47GeAt56Meta } from "/app/pages/collaborations/[id]/index.vue?macro=true";
import { default as _91id_93ao43rTyfHAMeta } from "/app/pages/collaborations/[id].vue?macro=true";
import { default as recover_46clientjvnovzU0P5Meta } from "/app/pages/collaborations/lightwaves/recover.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/app/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as reveal_46clientRa2RmLbEbvMeta } from "/app/pages/collaborations/lightwaves/reveal.client.vue?macro=true";
import { default as _91_46_46_46slug_932oWhD5grg8Meta } from "/app/pages/collections/[...slug].vue?macro=true";
import { default as about3ajU9HAaRdMeta } from "/app/pages/collections/[id]/about.vue?macro=true";
import { default as _91inscriptionId_93FgSDBBE1KMMeta } from "/app/pages/collections/[id]/index/[inscriptionId].vue?macro=true";
import { default as indexKgXmXtS9Z8Meta } from "/app/pages/collections/[id]/index.vue?macro=true";
import { default as _91id_93skyE6bVvaPMeta } from "/app/pages/collections/[id].vue?macro=true";
import { default as indexv7p7e2w6rrMeta } from "/app/pages/collections/index.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as thank_45youqZCERJFQk0Meta } from "/app/pages/contacts/artist-apply/thank-you.vue?macro=true";
import { default as thank_45youK5OceyJkcoMeta } from "/app/pages/contacts/discovery-call/thank-you.vue?macro=true";
import { default as formOMTUqKpsEPMeta } from "/app/pages/contacts/looming-emotion/form.vue?macro=true";
import { default as thank_45youMLuPpyok8YMeta } from "/app/pages/contacts/looming-emotion/thank-you.vue?macro=true";
import { default as discovery_45callMUaZUuueylMeta } from "/app/pages/discovery-call.vue?macro=true";
import { default as indexghOkQKZ9xtMeta } from "/app/pages/distribution/[id]/[inscriptionId]/index.vue?macro=true";
import { default as _91id_9363MWgaUtZDMeta } from "/app/pages/distribution/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as join5y5cAZNJvoMeta } from "/app/pages/join.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "artist-apply",
    path: "/artist-apply",
    component: () => import("/app/pages/artist-apply.vue")
  },
  {
    name: "artists-name",
    path: "/artists/:name()",
    component: () => import("/app/pages/artists/[name].vue")
  },
  {
    name: "artists",
    path: "/artists",
    component: () => import("/app/pages/artists/index.vue")
  },
  {
    name: _91id_936iBnDYM7gSMeta?.name,
    path: "/chefs-doeuvre/:id()",
    component: () => import("/app/pages/chefs-doeuvre/[id].vue"),
    children: [
  {
    name: "chefs-doeuvre-id-about",
    path: "about",
    meta: aboutGlAwnqAYTEMeta || {},
    component: () => import("/app/pages/chefs-doeuvre/[id]/about.vue")
  },
  {
    name: "chefs-doeuvre-id",
    path: "",
    meta: indexoR8tiwGpXoMeta || {},
    component: () => import("/app/pages/chefs-doeuvre/[id]/index.vue"),
    children: [
  {
    name: "chefs-doeuvre-id-index-inscriptionId",
    path: ":inscriptionId()",
    component: () => import("/app/pages/chefs-doeuvre/[id]/index/[inscriptionId].vue")
  }
]
  }
]
  },
  {
    name: "chefs-doeuvre-ariadna",
    path: "/chefs-doeuvre/ariadna",
    component: () => import("/app/pages/chefs-doeuvre/ariadna.vue")
  },
  {
    name: _91id_93ao43rTyfHAMeta?.name,
    path: "/collaborations/:id()",
    component: () => import("/app/pages/collaborations/[id].vue"),
    children: [
  {
    name: "collaborations-id-about",
    path: "about",
    meta: aboutxYr78EWhorMeta || {},
    component: () => import("/app/pages/collaborations/[id]/about.vue")
  },
  {
    name: "collaborations-id",
    path: "",
    meta: indexfL47GeAt56Meta || {},
    component: () => import("/app/pages/collaborations/[id]/index.vue"),
    children: [
  {
    name: "collaborations-id-index-inscriptionId",
    path: ":inscriptionId()",
    component: () => import("/app/pages/collaborations/[id]/index/[inscriptionId].vue")
  }
]
  }
]
  },
  {
    name: "collaborations-lightwaves-recover",
    path: "/collaborations/lightwaves/recover",
    component: () => createClientPage(() => import("/app/pages/collaborations/lightwaves/recover.client.vue"))
  },
  {
    name: "collaborations-lightwaves-reveal",
    path: "/collaborations/lightwaves/reveal",
    component: () => createClientPage(() => import("/app/pages/collaborations/lightwaves/reveal.client.vue"))
  },
  {
    name: "collections-slug",
    path: "/collections/:slug(.*)*",
    component: () => import("/app/pages/collections/[...slug].vue")
  },
  {
    name: _91id_93skyE6bVvaPMeta?.name,
    path: "/collections/:id()",
    component: () => import("/app/pages/collections/[id].vue"),
    children: [
  {
    name: "collections-id-about",
    path: "about",
    component: () => import("/app/pages/collections/[id]/about.vue")
  },
  {
    name: "collections-id",
    path: "",
    component: () => import("/app/pages/collections/[id]/index.vue"),
    children: [
  {
    name: "collections-id-index-inscriptionId",
    path: ":inscriptionId()",
    component: () => import("/app/pages/collections/[id]/index/[inscriptionId].vue")
  }
]
  }
]
  },
  {
    name: "collections",
    path: "/collections",
    meta: indexv7p7e2w6rrMeta || {},
    alias: ["/chefs-doeuvre","/collaborations"],
    component: () => import("/app/pages/collections/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/app/pages/contact.vue")
  },
  {
    name: "contacts-artist-apply-thank-you",
    path: "/contacts/artist-apply/thank-you",
    component: () => import("/app/pages/contacts/artist-apply/thank-you.vue")
  },
  {
    name: "contacts-discovery-call-thank-you",
    path: "/contacts/discovery-call/thank-you",
    component: () => import("/app/pages/contacts/discovery-call/thank-you.vue")
  },
  {
    name: "contacts-looming-emotion-form",
    path: "/contacts/looming-emotion/form",
    component: () => import("/app/pages/contacts/looming-emotion/form.vue")
  },
  {
    name: "contacts-looming-emotion-thank-you",
    path: "/contacts/looming-emotion/thank-you",
    component: () => import("/app/pages/contacts/looming-emotion/thank-you.vue")
  },
  {
    name: "discovery-call",
    path: "/discovery-call",
    component: () => import("/app/pages/discovery-call.vue")
  },
  {
    name: "distribution-id",
    path: "/distribution/:id()",
    component: () => import("/app/pages/distribution/[id].vue"),
    children: [
  {
    name: "distribution-id-inscriptionId",
    path: ":inscriptionId()",
    component: () => import("/app/pages/distribution/[id]/[inscriptionId]/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/app/pages/join.vue")
  }
]